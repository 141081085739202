const en = {
  translation: {
    yes: 'Yes',
    no: 'No',
    loading: 'Loading...',
    keyData: 'Key Data',
    bondInfo: 'Bond Info',
    performance: 'Performance',
    issuedBy: 'Issued by',
    maturity: 'Maturity',
    profile: 'Profile',
    backToOverview: 'Back to Overview',
    disclaimer:
      'Please note that the displayed data does not refresh automatically. Please refresh the entire page in the browser menu to see the most recent dynamic data.',
    orderbook: {
      title: 'Orderbook',
      headers: {
        cumVolume: 'Cum. Volume',
        bidVolumne: 'Bid Volume',
        price: 'Price',
        askVolume: 'Ask Volume',
        cumVolume2: 'Cum. Volume',
      },
    },
    isin: 'ISIN',
    symbol: 'Symbol',
    countryCode: 'Country Code',
    issuerCountryCode: 'Issuer Country Code',
    indexMember: 'Index Member',
    more: 'more',
    dividend: 'Dividend',
    mainRegister: {
      title: 'Main Register',
      record: 'Record',
      errorTitle: 'Something went wrong...',
      headers: {
        isin: 'ISIN',
        localSecurityId: 'Local Security ID',
        assetId: 'Asset ID',
        description: 'Description',
        nominalValue: 'Nominal Value',
        nominalCurrency: 'Nominal Currency',
        totalIssuedQuantity: 'Total Issued Quantity',
      },
    },
    currencyHolidays: {
      title: 'Holiday Calendar',
      record: 'Record',
      errorTitle: 'Something went wrong...',
      headers: {
        date: 'Date',
        name: 'Name',
        currencies: 'Currencies',
      },
      disclaimer:
        'The SIX Digital Exchange Currency Holiday Calendar shows the days on which national banks are closed for a public holiday. There is no settlement in the respective currency on these days. Any weekdays not included in the Currency Holiday Calendar are considered normal days with settlement in the relevant currency.',
    },
    shares: {
      description: 'The share explorer allows you to compare stocks within an index or make index-independent comparisons.',
      explorer: 'Share Explorer',
      tabs: {
        overview: 'Overview',
        details: 'Details',
        orderbooks: 'Orderbook',
        companyDetails: 'Company Details',
      },
      headers: {
        issuer: 'Issuer',
        symbol: 'Symbol',
        localSecurityID: 'Local Security ID',
        isin: 'ISIN',
        description: 'Description',
      },
      overview: {
        tradingActivity: {
          label: 'Trading Activity',
          volume: 'Volume',
          turnover: 'Turnover',
          numberOfTrades: 'Number of trades',
          onOrderBook: 'On-Book',
          offOrderBook: 'Off-Book',
        },
        keyData: {
          closingPrice: 'Closing Price',
          latestTradeVolume: 'Latest Trade Volume',
          bidPrice: 'Bid Price',
          askPrice: 'Ask Price',
          bidVolume: 'Bid Volume',
          askVolume: 'Ask Volume',
          previousClosingPrice: 'Previous Closing Price',
          openingPrice: 'Opening Price',
          totalVolume: 'Total Volume',
          dailyLowPrice: 'Daily Low Price',
          dailyLowTime: 'Daily Low Time',
          dailyHighPrice: 'Daily High Price',
          dailyHighTime: 'Daily High Time',
          optingUpOptingOut: 'Opting Up / Opting Out',
          managementTransactions: 'Management Transactions',
          significantShareholders: 'Significant Shareholders',
        },
        performance: {
          intradayOnExchangeValues: 'Intraday On-book Values',
          intradayOffExchangeValues: 'Intraday Off-book Values',
          historicalTradeValues: 'Historical Trade Values',
        },
      },
      details: {
        keyValue: {
          valorSymbol: 'Valor Symbol',
          tradingCurrency: 'Trading Currency',
          primaryListed: 'Primary Listed',
          digitalNative: 'Digital Native',
          valorNumber: 'Valor Number',
          productType: 'Product Type',
          smallestTradeableUnit: 'Smallest Tradeable Unit',
          isin: 'ISIN',
          trading: 'First Trading Date',
          issuedBy: 'Issued by',
          numberInIssue: 'Number In Issue',
          nominalValue: 'Nominal Value',
          dividentEntitlement: 'Dividend Entitlement',
          regulatoryStandard: 'Regulatory Standard',
          exDividendDate: 'Ex dividend date',
          dividendValue: 'Value',
          dividendCurrency: 'Currency',
        },
      },
      companyDetails: {
        accordion: {
          profile: 'Profile',
          investorRelations: 'Investor Relations',
          adHocPublicity: 'Ad Hoc Publicity',
          regularReportingObligations: 'Regular Reporting Obligations',
        },
        tabs: {
          labels: {
            reportedCapital: 'Reported Capital',
            financialReports: 'Financial Reports',
            corporateCalendar: 'Corporate Calendar',
            sustainabilityReport: 'Sustainability Report',
          },
          content: {
            listedShareCapitalUnits: 'Listed Share Capital Units',
            listedShareCapital: 'Listed Share Capital',
            conditionalCapitalUnits: 'Conditional Capital Units',
            conditionalCapital: 'Conditional Capital',
            authorizedCapitalUnits: 'Authorized Capital Units',
            authorizedCapital: 'Authorized Capital',
            shareCapitalUnits: 'Share Capital Units',
            shareCapital: 'Share Capital',
            annualClosingDate: 'Annual Closing Date',
            accountingStandard: 'Accounting Standard',
            auditor: 'Auditor',
            faoaRegisterNumber: 'Faoa Register Number',
            lastGeneralMeeting: 'Last General Meeting',
            nextGeneralMeeting: 'Next General Meeting',
            shareRegisterClosedByDate: 'Share Register Closed By Date',
            shareRegisterClosedByTime: 'Share Register Closed By Time',
            optingIn: 'Opting In',
            internationlRecognizedStandard: 'Internationl Recognized Standard',
            frequencyOfReporting: 'Frequency Of Reporting',
            webLinkToSustainability: 'Web Link To Sustainability',
          },
        },
      },
    },
    bonds: {
      explorer: 'Bond Explorer',
      tabs: {
        overview: 'Overview',
        details: 'Details',
        orderbooks: 'Orderbook',
      },
      overview: {
        tradingActivity: {
          label: 'Trading Activity',
          volume: 'Volume',
          turnover: 'Turnover',
          numberOfTrades: 'Number of trades',
          onOrderBook: 'On-Book',
          offOrderBook: 'Off-Book',
        },
        keyData: {
          previousClosingPrice: 'Previous Closing Price',
          openingPrice: 'Opening Price',
          issuerName: 'Issuer Name',
          maturityDate: 'Maturity Date',
        },
        bondInfo: {
          issueYear: 'Issue Year',
          remainingLifeInYears: 'Remaining Life In Years',
          couponRate: 'Coupon Rate',
          couponAmount: 'Coupon Amount',
          interestFrequency: 'Interest Frequency',
          startingDate: 'Interest Accrued from',
          interestCalculationMethod: 'Method of Interest Computation',
        },
        performance: {
          closingPrice: 'Closing Price',
          latestTradeVolume: 'Latest Trade Volume',
          totalVolume: 'Total Volume',
          bidPrice: 'Bid Price',
          askPrice: 'Ask Price',
          bidVolume: 'Bid Volume',
          askVolume: 'Ask Volume',
          dailyLowPrice: 'Daily Low Price',
          dailyLowTime: 'Daily Low Time',
          dailyHighPrice: 'Daily High Price',
          dailyHighTime: 'Daily High Time',
          download: 'Download',
          intradayOnExchangeValues: 'Intraday On-book Values',
          intradayOffExchangeValues: 'Intraday Off-book Values',
          historicalTradeValues: 'Historical Trade Values',
          delayedPublications: 'Delayed Publications',
        },
      },
      details: {
        keyData: {
          valorSymbol: 'Valor Symbol',
          valorNumber: 'Valor Number',
          isin: 'ISIN',
          tradingCurrency: 'Nominal Currency',
          exchange: 'Exchange',
          productLine: 'Product Line',
          trading: 'First Trading Date',
          smallestTradeableUnit: 'Minimum Order Quantity',
          listed: 'Listed',
          regulatoryStandard: 'Regulatory Standard',
          dutyToReport: 'Duty to Report',
          admissionState: 'Admission State',
          ragFulfilled: 'Rag Fulfilled',
          digitalNative: 'Digital Native',
        },
        issuedBy: {
          issuedBy: 'Issued by',
          denomination: 'Denomination',
          nominalAmount: 'Nominal Amount',
        },
        maturity: {
          maturity: 'Maturity',
          earliestRedemptionDate: 'Earliest Redemption Date',
          noticePeriod: 'Notice Period',
        },
      },
      headers: {
        isin: 'ISIN',
        ValorSymbol: 'Valor symbol',
        last: 'Last',
        description: 'Description',
        coupon: 'Coupon',
        issuer: 'Issuer',
        maturityDate: 'Maturity date',
        currency: 'Currency',
      },
    },
    documents: {
      title: 'Documents',
      headers: {
        url: '',
        date: 'Date',
        fileName: 'Description',
      },
    },
    officialNotices: {
      title: 'Official notices',
      headers: {
        date: 'Date',
        title: 'Title',
        type: 'Type',
        issuer: 'Issuer',
        isin: 'ISIN',
      },
      text: 'SIX Digital Exchange publishes the official notices of its listed companies on a daily basis.',
      details: {
        id: 'ID',
        date: 'Date',
        type: 'Type',
        issuer: 'Issuer',
        isin: 'ISIN',
        title: 'Title',
      },
    },
    news: {
      title: 'Trading News Board',
      text: '',
      headers: {
        date: 'Date',
        title: 'Title',
      },
      mistrade: {
        title: 'Trading Information Decision in {{isin}}',
        titleOverview: 'Mistrade Decision in {{isin}}',
        isin: 'ISIN',
        messageType: 'Message Type',
        priority: 'Priority',
        marketIndicator: 'Market indicator',
        broadcastTime: 'Broadcast time',
        currency: 'Currency',
        tradeDate: 'Trade date',
        tradeTime: 'Trade time',
        tradePrice: 'Trade price',
        tradeSize: 'Trade size',
        text: "In accordance with the rules of SDX Trading AG, the following trades in '{{isin}}' have been declared mistrades and have therefore been cancelled:",
        footer: 'Please find further information concerning mistrades in Directive 4: Market Control on our website.',
      },
      suspension: {
        suspensionTitleOverview: 'Suspension {{isin}}',
        resumptionTitleOverview: 'Resumption {{isin}}',
        messageIndicator: 'Message Indicator',
        suspension: 'Suspension',
        resumption: 'Resumption',
        titleSuspension: 'Suspension: {{isin}}',
        titleResumption: 'Resumption: {{isin}}',
        isin: 'ISIN',
        priority: 'Priority',
        marketIndicator: 'Market indicator',
        broadcastTime: 'Broadcast time',
        currency: 'Currency',
        textSuspension: 'SDX Trading AG has suspended the following security:',
        textResumption: 'SDX Trading AG has resumed the following security:',
        securityISIN: 'Security ISIN',
        footer: {
          part1: 'Regards,',
          part2: 'Exchange Operations, SDX Trading AG',
        },
      },
    },
    footer: {
      concerns: {
        part1:
          'SDX is concerned with keeping the information published here complete, correct and current. It is possible, however, that not all of the information is completely up to date. We appreciate ',
        link: 'feedback',
        part2: ' allowing us to correct any errors.',
      },
    },
  },
};

export default en;
