import React from 'react';
import '../i18n/i18next';
import { AgnosticGuiProvider } from 'sdx-dds-gui-lib';
import apolloClient from '../lib/apolloClient';
import styled from 'styled-components/macro';
import ErrorBoundary from './ErrorBoundry';
import Routes from './Routes';

const ScrollableContent = styled.div`
  & > div {
    overflow: auto !important;
  }
`;

export const App = () => {
  return (
    <ErrorBoundary>
      <ScrollableContent>
        <AgnosticGuiProvider errorBoundry={ErrorBoundary} routing={{}} graphql={{ apolloClient: apolloClient as never }} wrapper={() => <Routes />} />
      </ScrollableContent>
    </ErrorBoundary>
  );
};

export default React.memo(App);
