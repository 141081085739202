import { TFunction } from 'i18next';
import { camelCase } from 'lodash';

export const getLabelAndDescription = ({
  t,
  entityName,
  name,
  subType,
  enums,
  isOverview = false,
  type = 'generic',
}: {
  t: TFunction;
  entityName: string;
  name: string;
  subType?: string;
  enums?: string[];
  isOverview?: boolean;
  type?: 'generic' | 'category' | 'field' | 'typeSelector' | 'header' | 'dropText';
}): {
  label: string | undefined;
  typeLabel: string | undefined;
  description: string | undefined;
  enumsToLabels: Record<string, string | undefined> | undefined;
} => {
  const cEntityName = camelCase(entityName);
  const cName = camelCase(name);
  const cSubType = subType ? camelCase(subType) : undefined;
  const mainKey =
    cSubType && t(`labelAndDesc.${cEntityName}.${type}.${cSubType}.${cName}`) !== `labelAndDesc.${cEntityName}.${type}.${cSubType}.${cName}`
      ? `labelAndDesc.${cEntityName}.${type}.${cSubType}.${cName}`
      : `labelAndDesc.${cEntityName}.${type}.${cName}`;

  const labelKey = `${mainKey}.label`;
  const typeLabelKey = `${mainKey}.typeLabel`;
  const overviewLabelKey = `${mainKey}.overviewLabel`;
  const descriptionKey = `${mainKey}.description`;
  const enumsKey = `${mainKey}.enums`;
  const defaultLabel = t(labelKey, {
    defaultValue: type === 'header' ? t(`labelAndDesc.${cEntityName}.field.${cName}.label`, { defaultValue: labelKey }) : labelKey,
  });
  const overviewLabel = t(overviewLabelKey);
  const result = {
    label: isOverview ? (overviewLabel === overviewLabelKey ? defaultLabel : overviewLabel) : defaultLabel,
    typeLabel: t(typeLabelKey),
    description: t(descriptionKey),
    enumsToLabels:
      typeof enums !== 'undefined'
        ? enums?.reduce((acc, e) => {
            const currentEnumKey = `${enumsKey}.${e}`;
            const enumTranslation = t(currentEnumKey);
            return {
              ...acc,
              [e]: enumTranslation === currentEnumKey ? undefined : enumTranslation,
            };
          }, {})
        : undefined,
  };
  return {
    label: result.label === labelKey ? undefined : result.label,
    typeLabel: result.typeLabel === typeLabelKey ? undefined : result.typeLabel,
    description: result.description === descriptionKey ? undefined : result.description,
    enumsToLabels: result.enumsToLabels,
  };
};

export const getErrorLabelsFunc = ({
  t,
  entityName,
  name,
  messageKey,
}: {
  t: TFunction;
  entityName: string | undefined;
  name: string | undefined;
  messageKey: string | undefined;
}) => {
  const cEntityName = camelCase(entityName);
  const cName = name ? camelCase(name) : 'generic';
  const key = `errorLabels.${cEntityName}.${cName}.${messageKey}`;
  const result = t(key);
  return result !== key ? result : undefined;
};
