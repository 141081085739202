import 'core-js';
import './lib/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import App from './components/App';

const rootEl = document.getElementById('root');
const root = createRoot(rootEl as Element);
root.render(<App />);

// enable hmr
if (module.hot) {
  module.hot.accept('./components/App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('./components/App').default;
    root.render(<NextApp />);
  });
}
