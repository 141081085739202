import React from 'react';
import axios from 'axios';
import styled from 'styled-components/macro';
import { Box } from 'grommet';
import { base } from 'sdx-dds-gui-lib';
import { themeStyles } from 'sdx-dds-gui-lib';

const breakpoints = themeStyles.theme.global.breakpoints;

const {
  organisms: { LayoutContent },
  atoms: {
    Icons: { ErrorIcon },
  },
} = base;

const MessageBox = styled(Box)`
  display: flex;
  flex-direction: row;
  font-family: 'Noto Sans';
  font-size: 16px;
  border-radius: 3px;
  background-color: #e84d47;
  box-shadow: 0 0 0 1px rgb(28 33 34 / 2%), 0 1px 3px 0 rgb(38 42 44 / 15%);
  color: #ffffff;
  padding: 16px;
  @media (max-width: ${breakpoints.small.value}px) {
    font-size: 10px;
  }
`;

interface Props {
  msg?: string;
}

interface State {
  hasError: boolean;
}
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // @ts-ignore
  componentDidCatch(error, errorInfo) {
    axios({
      url: '/log',
      method: 'post',
      data: {
        productName: 'public-portal',
        productVersion: '0.1.0',
        msg: `Portal Error details: ${this.props.msg ?? '(no custom msg)'} ||| React error details: [error: ${
          error ?? {}
        }, errorInfo: ${JSON.stringify(errorInfo ?? {})}]`,
        level: 'error',
      },
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <LayoutContent
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: '25%',
          }}
        >
          <MessageBox>
            <ErrorIcon style={{ marginRight: '10px' }} />
            Something went wrong. Please refresh the entire page.
          </MessageBox>
        </LayoutContent>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
