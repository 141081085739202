const rootPath = '';

export const Paths = Object.freeze({
  mainRegister: rootPath + '/main-register',
  currencyHolidays: rootPath + '/currency-holidays',
  bonds: rootPath + '/bonds',
  bond: rootPath + '/bonds/:id/:tab?',
  shares: rootPath + '/shares',
  share: rootPath + '/shares/:id/:tab?',
  news: rootPath + '/news',
  mistrade: rootPath + '/news/mistrade/:id',
  suspension: rootPath + '/news/suspension/:id',
  documents: rootPath + '/documents',
  officialNotices: rootPath + '/official-notices',
  officialNotice: rootPath + '/official-notices/:id',
});

export const routeTo = {
  mainRegister: () => Paths.mainRegister,
  bonds: () => Paths.bonds,
  bond: (id: string, tab = 'overview') => Paths.bond.replace(':id', id).replace(':tab?', tab),
  shares: () => Paths.shares,
  share: (id: string, tab = 'overview') => Paths.share.replace(':id', id).replace(':tab?', tab),
  news: () => Paths.news,
  mistrade: (id: string) => Paths.mistrade.replace(':id', id),
  suspension: (id: string) => Paths.suspension.replace(':id', id),
  documents: () => Paths.documents,
  officialNotices: () => Paths.officialNotices,
  officialNotice: (id: string) => Paths.officialNotice.replace(':id', id),
};
