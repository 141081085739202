import i18n from 'i18next';
import { initReactI18next, UseTranslationResponse } from 'react-i18next';
import moment from 'moment';
import en from './languages/en';
import { ddsGuiTranslations, setGetLabelAndDescriptionFunc, setGetErrorLabelsFunc } from 'sdx-dds-gui-lib';
import { getLabelAndDescription, getErrorLabelsFunc } from './utils';

export const resources = {
  en,
} as const;

export type I18nResources = typeof resources;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss,
      format: (value, format) => {
        if (format) {
          const momentDate = moment(value);
          return momentDate.isValid() ? momentDate.format(format) : value;
        }
        return value;
      },
    },
  });

i18n.addResourceBundle('en', 'ddsGui', ddsGuiTranslations.en);

setGetLabelAndDescriptionFunc(getLabelAndDescription);
setGetErrorLabelsFunc(getErrorLabelsFunc);

export default i18n;

// @ts-ignore
export type TFunction = UseTranslationResponse['t'];
